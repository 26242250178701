
export default {
    name: "JobFairSeoContent",
    components: {},
    data() {
        return {
            logos: [
                {
                    src: require("@/assets/images/fair/altmuehlfranken/partners.jpg"),
                    alt: "Partnerunternehmen",
                },
            ],
        }
    },
    methods: {},
}
